















































































































































































































































































































































































import {
  computed,
  defineComponent,
  inject,
  onMounted,
  Ref,
  ref,
  watch
} from '@vue/composition-api';

import { getMinutes, parseISO } from 'date-fns';

import { Report, ReportInput } from '@/models/report.model';

import { useActions, useGetters } from 'vuex-composition-helpers';
import {
  FETCH_ASSET,
  REMOVE_IMAGE,
  UPLOAD_IMAGE
} from '@/store/operation.actions';
import getHours from 'date-fns/getHours';
import { Emitter } from 'mitt';

import { CREATE_OR_SAVE_REPORT, GET_REPORTS } from '@/store/report.actions';

import { VForm } from '@/types/form.type';
import {
  clearAssets as clsAssets,
  clearPreviewAsset as clsPreviewAsset,
  clearNewAssets as clsNewAssets,
  fetchPreviewAssetForImageEntry,
  singleUrl,
  url,
  updateEntryEditAssets,
  clearAssetsUrls,
  sendEntryForm,
  getTimeString,
  getDateString,
  imageEntryWatcher
} from '@/helpers/image-entry-helpers';
import { AssetType } from '@/types/asset.type';
import { dateRules, timeRules, shortReportRules } from '@/helpers/common-rules';

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import { SelectEntry } from '@/types/select-entry.type';

export default defineComponent({
  name: 'ReportForm',
  props: {
    id: {
      type: String
    }
  },
  components: {
    VuePdfEmbed
  },
  setup: (props, ctx) => {
    const getters = useGetters([GET_REPORTS]);

    // get report
    const reports = getters[GET_REPORTS];

    const id = props.id;
    const report = (reports.value as Report[]).find(rep => rep._id === id);

    const valid = ref(false);
    const form = ref();

    const shortReport = ref(report?.kurzbericht ?? '');
    const title = ref(report?.titel ?? '');

    // Datum
    const dateMenu = ref(false);
    const timestampInput = report?.zeitstempel
      ? parseISO(report?.zeitstempel)
      : null;

    const date = ref(getDateString(timestampInput));
    const localeDate = computed(() => {
      return new Date(date.value).toLocaleDateString();
    });
    const time: Ref<string> = ref(getTimeString(timestampInput));
    const timeMenu = ref(false);

    const text = ref(report?.text ?? '');

    const imgAssets = ref([] as File[]);
    const imgAssetUrls = ref([] as string[]);
    const imageUrl = () => {
      url(imgAssets, imgAssetUrls);
    };

    const pdfAssets = ref([] as File[]);
    const pdfAssetUrls = ref([] as string[]);
    const pdfUrl = () => {
      url(pdfAssets, pdfAssetUrls);
    };

    const previewAsset = ref();
    const previewAssetUrl = ref('');
    const previewAssetToType = ref({} as { [key: string]: AssetType });
    const previewUrl = () => {
      singleUrl(previewAsset, previewAssetUrl, previewAssetToType);
    };

    const actions = useActions([
      UPLOAD_IMAGE,
      CREATE_OR_SAVE_REPORT,
      FETCH_ASSET,
      REMOVE_IMAGE
    ]);

    const isEditing = ref(!!props.id);
    const confirmImgDialog = ref(false);
    const confirmPdfDialog = ref(false);
    const confirmEditAssetDialog = ref(false);

    const isPreviewEditingFirst = ref(true);
    const confirmPreviewDialog = ref(false);
    const useInputPreviewAsset = ref(false);

    const removeImageAction = actions[REMOVE_IMAGE];
    const createSaveReportAction = actions[CREATE_OR_SAVE_REPORT];
    const $emitter = inject('$emitter') as Emitter;

    const editAssets = ref([] as SelectEntry[]);
    const selectedEditAssets = ref([] as string[]);
    const clearAssets = async () => {
      clsAssets(
        selectedEditAssets.value.map(a => ({ _id: a })),
        confirmEditAssetDialog,
        report,
        removeImageAction,
        createSaveReportAction,
        $emitter
      );
    };

    const clearNewPdfAssets = async () => {
      clsNewAssets(pdfAssetUrls, pdfAssets, confirmImgDialog);
    };

    const clearNewImgAssets = async () => {
      clsNewAssets(imgAssetUrls, imgAssets, confirmImgDialog);
    };

    const clearPreviewAsset = async () => {
      previewAssetToType.value = {};
      clsPreviewAsset(
        isEditing,
        previewAssetUrl,
        previewAsset,
        confirmPreviewDialog,
        isPreviewEditingFirst,
        useInputPreviewAsset,
        report,
        removeImageAction,
        createSaveReportAction,
        $emitter
      );
    };

    const fetchAssetAction = actions[FETCH_ASSET];
    const updateEditAssets = async (report: Report) => {
      await updateEntryEditAssets(
        report,
        editAssets,
        fetchAssetAction,
        imgAssetUrls,
        pdfAssetUrls
      );
    };

    onMounted(async () => {
      if (!report) {
        return;
      }

      useInputPreviewAsset.value =
        !!report.vorschaubild && report.vorschaubild.length > 0;

      if (Array.isArray(report.bilder)) {
        updateEditAssets(report);
      }

      if (useInputPreviewAsset.value) {
        fetchPreviewAssetForImageEntry(
          fetchAssetAction,
          report.vorschaubild as string,
          previewAssetUrl,
          previewAsset,
          previewAssetToType
        );
      }
    });

    // update edit assets after upload of new images
    watch(reports, (newReports, _oldReports) => {
      imageEntryWatcher(
        newReports,
        editAssets,
        id,
        fetchAssetAction,
        imgAssetUrls,
        pdfAssetUrls
      );
    });

    const uploadImageAction = actions[UPLOAD_IMAGE];
    const sendForm = async () => {
      const res = await sendEntryForm(
        form,
        imgAssets,
        pdfAssets,
        uploadImageAction,
        useInputPreviewAsset,
        previewAsset,
        time,
        report as Report,
        isEditing,
        date,
        createSaveReportAction,
        (r: ReportInput) => {
          r.titel = title.value;
          r.kurzbericht = shortReport.value;
          r.text = text.value;
        }
      );

      if (res) {
        // location.reload();
        setTimeout(() => ctx.root.$router.go(0), 500);

        // if (!isEditing.value && res?._id?.length > 0) {
        //   const vform = form?.value as VForm;
        //   vform?.resetValidation();

        //   const now = new Date();
        //   date.value = now.toISOString().substr(0, 10);
        //   time.value = `${getHours(now)}:${getMinutes(now) < 10 ? 0 : ''}${getMinutes(now)}`;
        //   title.value = '';
        //   shortReport.value = '';
        //   text.value = '';

        //   // Assets aufräumen
        //   clearAssetsUrls(
        //     imgAssetUrls,
        //     pdfAssetUrls,
        //     previewAssetUrl,
        //     imgAssets,
        //     pdfAssets,
        //     previewAsset,
        //     previewAssetToType
        //   );
        // }
      }
    };

    const titleRules = ref([
      (v: string) => (!!v && v.length > 0) || 'Bitte Titel angeben'
    ]);

    return {
      form,
      valid,
      location,
      title,
      shortReport,
      dateMenu,
      date,
      localeDate,
      time,
      timeMenu,
      text,
      imgAssets,
      imgAssetUrls,
      imageUrl,
      pdfAssets,
      pdfAssetUrls,
      pdfUrl,
      clearAssets,
      confirmImgDialog,
      confirmPdfDialog,
      confirmEditAssetDialog,
      isEditing,
      editAssets,
      selectedEditAssets,
      clearNewPdfAssets,
      clearNewImgAssets,
      useInputPreviewAsset,
      previewAsset,
      previewAssetUrl,
      previewAssetToType,
      previewUrl,
      clearPreviewAsset,
      confirmPreviewDialog,
      isPreviewEditingFirst,

      sendForm,
      dateRules,
      timeRules,
      titleRules,
      shortReportRules
    };
  }
});
