import { ref } from '@vue/composition-api';

export const dateRules = ref([
  (v: string) => (!!v && v.length > 0) || 'Bitte Datum angeben'
]);

export const timeRules = ref([
  (v: string) => (!!v && v.length > 0) || 'Bitte Zeit angeben'
]);

export const shortReportRules = ref([
  (v: string) => (!!v && v.length > 0) || 'Bitte Kurzbericht angeben'
]);
